
.material-flow {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem 0;
  box-sizing: border-box;

  .material-box {
    box-sizing: border-box;
    flex: 1;
    background: #fff;
    padding: 0 0.18rem;
    box-sizing: border-box;

    .material-title {
      height: 1.08rem;
      display: flex;
      align-items: center;
      font-size: 0.26rem;
      color: #333333;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 0.5rem;
    }

    .material-line {
      margin-bottom: 0.7rem;
      display: flex;
      align-items: center;
      color: #999999;

      .time {
        width: 0.8rem;
        margin-right: 0.66rem;

        p {
          text-align: center;
          white-space: nowrap;
        }

        p:nth-child(1) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }

        p:nth-child(2) {
          font-size: 0.22rem;
          line-height: 0.32rem;
        }
      }

      .info {
        p:nth-child(1) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }

        p:nth-child(2) {
          font-size: 0.24rem;
          line-height: 0.34rem;
        }
      }
    }

    .material-line:nth-child(1) {
      color: #333333;
    }
  }
}
